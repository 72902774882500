import queryString from 'query-string'
import onClickOutside from 'react-onclickoutside'
import routeUrlHelper from './helpers/route_url_helper'
import languageHelper from './helpers/language_helper'

window.queryString = queryString
window.onClickOutside = onClickOutside

window.routeUrlHelper = routeUrlHelper
window.languageHelper = languageHelper
