import qs from 'qs'
import isValidDate from './is_valid_date'

class RouteUrlHelper {
  listingPath(id, { checkin, checkout, rooms, adults } = {}) {
    const query = {}
    if (isValidDate(checkin)) {
      query.checkin = checkin
    }
    if (isValidDate(checkout)) {
      query.checkout = checkout
    }
    if ((rooms | 0) > 0) {
      query.rooms = rooms
    }
    if ((adults | 0) > 0) {
      query.adults = adults
    }
    const q = queryString.stringify(query)
    return `/listings/${id}${q ? `?${q}` : ''}`
  }

  classificationsPostsPath (zipCode) {
    return zipCode ? `/classifications/posts?zip_code=${zipCode}` : `/classifications/posts`
  }

  classificationsPropertyTypesPath () {
    return '/classifications/property_types'
  }

  classificationsRoomTypesPath () {
    return `/classifications/room_types`
  }

  classificationsHouseRulesPath () {
    return `/classifications/house_rules`
  }

  classificationsValidationsPath () {
    return `/classifications/validations`
  }

  classificationsCancelPoliciesPath () {
    return `/classifications/cancel_policies`
  }

  classificationsAmenitiesPath () {
    return `/classifications/amenities`
  }

  classificationsHomeSafetiesPath () {
    return `/classifications/home_safeties`
  }

  classificationsBedTypesPath () {
    return `/classifications/bed_types`
  }

  classificationsDisinfectionMeasuresPath () {
    return `/classifications/disinfection_measures`
  }

  classificationsCountriesPath () {
    return `/classifications/countries`
  }

  classificationsAllCountriesPath () {
    return `/classifications/countries/all`
  }

  classificationsStatesPath () {
    return `/classifications/states`
  }

  searchPath (query, { arrayFormat = 'none' } = {}) {
    const search = queryString.stringify(query, { arrayFormat })
    return `/search${search ? `?${search}` : ''}`
  }

  roomCalendarSourcesPath(id) {
    return `/rooms/${id}/calendar_sources`
  }

  roomGroupCalendarSourcesPath(id) {
    return `/room_groups/${id}/calendar_sources`
  }

  roomIcalPath(id) {
    return `/ical/rooms/${id}`
  }

  roomGroupIcalPath(id) {
    return `/ical/room_groups/${id}`
  }

  openidLoginUrlPath () {
    return '/openid/login_url'
  }

  loginUsersPath ({ returnUrl } = {}) {
    return returnUrl ? `/users/login?return_url=${encodeURIComponent(returnUrl)}` : `/users/login`
  }

  isLoginUsersPath () {
    return '/users/is_login'
  }

  meUsersPath () {
    return '/users/me'
  }

  meBankAccountsPath () {
    return '/bank_accounts/me'
  }

  reviewsPath (userId = 'me') {
    return `/users/${userId}/reviews`
  }

  reviewPath (userId, reservationId) {
    return `/users/${userId}/reviews/${reservationId}`
  }

  userNotificationSettingsPath (id) {
    return `/users/${id}/notification_settings`
  }

  userPhotosPath(id) {
    return `/users/${id}/photos`
  }

  userPhotoPath(userId, photoId) {
    return `/users/${userId}/photos/${photoId}`
  }

  attributeUsersPath() {
    return '/users/attribute'
  }

  userPath(id) {
    return `/users/${id}`
  }

  phoneNumberConfirmationUsersPath() {
    return '/users/phone_number_confirmation'
  }

  listingCalendarsPath (id) {
    return `/listings/${id}/calendars`
  }

  paymentsBooksPath () {
    return '/payments/books'
  }

  listingPricesPath (id) {
    return `/listings/${id}/prices`
  }

  previewPricesPath () {
    return `/prices/preview`
  }

  listingReviewsPath(id) {
    return `/listings/${id}/reviews`
  }

  propertyPath(id) {
    return `/properties/${id}`
  }

  propertiesPath () {
    return '/properties'
  }

  propertyPhotosPath (id) {
    return `/properties/${id}/photos`
  }

  propertyDisinfectionMeasuresPath(id, { format } = {}) {
    return `/properties/${id}/disinfection_measures${format ? `.${format}` : ''}`
  }

  roomGroupPath (id) {
    return `/room_groups/${id}`
  }

  roomGroupsPath () {
    return `/room_groups`
  }

  roomGroupBedTypesPath (id) {
    return `/room_groups/${id}/bed_types`
  }

  roomGroupBedTypePath ({ roomGroupId, bedTypeId }) {
    return `/room_groups/${roomGroupId}/bed_types/${bedTypeId}`
  }

  newListingHistoryPath () {
    return `/new_listing/history`
  }

  dashboardPropertiesPath () {
    return `/dashboard/properties`
  }

  newListingRouteChangePath () {
    return `/new_listing/route_change`
  }

  roomGroupBasicPriceSettingsPath (id) {
    return `/room_groups/${id}/basic_price_settings`
  }

  roomGroupCancelPoliciesPath (id) {
    return `/room_groups/${id}/cancel_policies`
  }

  roomGroupLicensePath (id) {
    return `/room_groups/${id}/license`
  }

  roomGroupAmenitiesPath (id) {
    return `/room_groups/${id}/amenities`

  }

  reservationsPath() {
    return '/dashboard/reservations'
  }

  reservationPath(id) {
    return `/dashboard/reservations/${id}`
  }

  newListingInitialPath ({ propertyId, roomGroupId, lawType } = {}) {
    const query = newListingQueryString({ propertyId, roomGroupId, lawType })
    return query === '' ? `/new_listing/initial` : `/new_listing/initial?${query}`
  }

  newListingListingPath ({ propertyId, roomGroupId, lawType } = {}) {
    const query = newListingQueryString({ propertyId, roomGroupId, lawType })
    return `/new_listing/listing?${query}`
  }

  editRoomGroupPath (id) {
    return `/room_groups/${id}/edit`
  }

  roomGroupCalendarPath (id) {
    return `/room_groups/${id}/calendar`
  }

  roomGroupListingPhotoPath (id) {
    return `/room_groups/${id}/listing_photo`
  }

  roomGroupPreviewPath (id) {
    return `/room_groups/${id}/preview`
  }

  editUserPath (id) {
    return `/users/${id}/edit`
  }

  photoUsersPath () {
    return `/users/photo`
  }

  editUserPasswordPath (id) {
    return `/users/${id}/password/edit`
  }

  logoutUsersPath ({ returnUrl } = {}) {
    return returnUrl ? `/users/logout?return_url=${returnUrl}` : `/users/logout`
  }

  usersPath ({ returnUrl } = {}) {
    return returnUrl ? `/users?return_url=${encodeURIComponent(returnUrl)}` : `/users`
  }

  editPropertyPath (id) {
    return `/properties/${id}/edit`
  }

  roomGroupRoomPath (roomGroupId, roomId) {
    return `/room_groups/${roomGroupId}/rooms/${roomId}`
  }

  rootPath () {
    return `/`
  }

  availablePropertiesPath () {
    return `/properties/available`
  }

  travelsPath () {
    return `/travels`
  }

  travelPath(id) {
    return `/travels/${id}`
  }

  travelReviewPath(id, format = 'html') {
    return `/travels/${id}/review.${format}`
  }

  travelInvoicePath(id, format = 'html') {
    return `/travels/${id}/invoice.${format}`
  }

  reservationGuestPath () {
    return `/reservations/guest`
  }

  editBankAccountsPath () {
    return `/bank_accounts/edit`
  }

  salesPath () {
    return `/sales`
  }

  salesInvoicePath({ format, ...query } = {}) {
    const q = queryString.stringify(query)
    return `/sales/invoice${format ? `.${format}` : ''}${q ? `?${q}` : ''}`
  }

  qualifiedInvoiceIssuerPath({ format } = {}) {
    return `/users/me/qualified_invoice_issuer${format ? `.${format}` : ''}`
  }

  roomGroupRoomsPath (id) {
    return `/room_groups/${id}/rooms`
  }

  roomGroupHomeSafetyPath (id) {
    return `/room_groups/${id}/home_safety`
  }

  roomGroupReservationRequirementsPath(id) {
    return `/room_groups/${id}/reservation_requirements`
  }

  generateNewPasswordEmailUsersPath () {
    return `/users/generate_new_password_email`
  }

  resetPasswordUsersPath () {
    return `/users/reset_password`
  }

  resendConfirmationEmailUsersPath () {
    return `/users/resend_confirmation_email`
  }

  roomGroupCheckinPath (id) {
    return `/room_groups/${id}/checkin`
  }

  roomGroupCancelPolicyPath (id) {
    return `/room_groups/${id}/cancel_policy`
  }

  roomGroupAmenityPath (id) {
    return `/room_groups/${id}/amenity`
  }

  roomGroupSettingPath (id) {
    return `/room_groups/${id}/setting`
  }

  roomGroupCalendarsPath (id) {
    return `/room_groups/${id}/calendars`
  }

  availableRoomGroupRoomsPath(id) {
    return `/room_groups/${id}/rooms/available`
  }

  roomCalendarsPath (id) {
    return `/rooms/${id}/calendars`
  }

  hostSalesListReservationsPath () {
    return `/reservations/host_sales_list`
  }

  hostSalesSummaryReservationsPath () {
    return `/reservations/host_sales_summary`
  }

  monthlySalesPath () {
    return `/monthly_sales`
  }

  roomGroupHouseRulesPath(id) {
    return `/room_groups/${id}/house_rules`
  }

  roomVerificationsPath(id) {
    return `/rooms/${id}/verifications`
  }

  propertyVerificationPath(id) {
    return `/properties/${id}/verification`
  }

  bankAccountEditPath() {
    return '/bank_accounts/edit'
  }

  roomGroupPhotosPath(id) {
    return `/room_groups/${id}/photos`
  }

  roomGroupPhotoPath (roomGroupId, photoId) {
    return `/room_groups/${roomGroupId}/photos/${photoId}`
  }

  countryPath(country) {
    return `/country/${country}`
  }

  countrySearchPath(country, query, { arrayFormat = 'none' } = {}) {
    return `${this.countryPath(country)}${this.searchPath(query, { arrayFormat })}`
  }

  featureTeleworkPath({ format, ...rest } = {}, { arrayFormat = 'none' } = {}) {
    const search = queryString.stringify(rest, { arrayFormat })
    return `/feature-telework${format ? `.${format}` : ''}?${search}`
  }

  couponsPath (userId = 'me') {
    return `/users/${userId}/coupons`
  }

  couponPropertyRegistryPath (couponId, propertyId) {
    return `/coupons/${couponId}/properties/${propertyId}/registry`
  }

  gotoCouponQRPath(reservationId) {
    return `/coupon/goto/${reservationId}/qr.svg`
  }

  paymentCompletePath(reservationId) {
    return `/payments/complete/${reservationId}`
  }
}

function newListingQueryString({ propertyId, roomGroupId, lawType }) {
  const query = {}
  if(propertyId) {
    query.property_id = propertyId
  }
  if(roomGroupId) {
    query.room_group_id = roomGroupId
  }
  if(lawType) {
    query.law_type = lawType
  }
  return qs.stringify(query)
}

const routeUrlHelper = new RouteUrlHelper()

export default routeUrlHelper
