const languages = [
  {
    code: 'ja',
    name: '日本語'
  },
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'zh-TW',
    name: '中文(繁体字)'
  }
  /*
  {
    code: 'zh_CN',
    name: '中文(简体字)'
  },
  {
    code: 'ko',
    name: '한국어'
  }
  */
]

export default languages
