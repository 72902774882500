import Cookies from 'js-cookie'
import GetApi from '../api/get'
import PutApi from '../api/put'
import languages from '../constants/languages'

export class LanguageHelper {
  constructor() {
    this.COOKIE_KEY = 'locale'
    this.JP_HOSTNAME = 'vacation-stay.jp'
    this.EN_HOSTNAME = 'vacation-stay.com'
    this.AVAILABLE_LANG = languages.map((lang) => {
      return lang.code
    }).join('|')
  }

  async getLanguage(locale) {
    let result = ''
    const getApi = new GetApi()
    await getApi.execute(routeUrlHelper.isLoginUsersPath(), { locale: this.pageLocale }).then(async (res) => {
      if (res.body == true) {
        await getApi.execute(routeUrlHelper.meUsersPath()).then((res) => {
          if (res.body.language) {
            result = res.body.language
          }
        })
      }
    })

    if (!result) {
      if (locale && this.validateLocale(locale)) {
        result = locale
      } else {
        result = Cookies.get(this.COOKIE_KEY)
      }
    }
    this.setLanguage(result)
    return result
  }

  setLanguage(locale) {
    if (this.validateLocale(locale)) {
      this.redirectIfRequired(locale)
      this.putLanguageToUserInfo(locale)
      Cookies.remove(this.COOKIE_KEY)
      Cookies.set(this.COOKIE_KEY, locale)
      this.setPathParam(locale)
    }
  }

  putLanguageToUserInfo(locale) {
    const getApi = new GetApi()
    const putApi = new PutApi()
    getApi.execute(routeUrlHelper.isLoginUsersPath(), { locale: this.pageLocale }).then((res) => {
      if (res.body == true) {
        putApi.execute(routeUrlHelper.meUsersPath(), { language: locale }).catch((res) => {})
      }
    })
  }

  setPathParam(locale) {
    if (window.history.replaceState) {
      const path = (locale == 'ja' || locale == 'en' || locale == 'zh-TW')
        ? this.removeLocalePath()
        : this.appendLocalePath(locale)
      const url = location.href.replace(/(https?:\/\/[^/]+)\/[^?]*(\?.+)?$/, `$1${path}$2`)
      history.replaceState({ path: url }, '', url)
    }
  }

  appendLocalePath(locale) {
    let path = this.removeLocalePath()
    if (this.validateLocale(locale)) {
      path = `/${locale}${path}`
    }
    return path
  }

  removeLocalePath() {
    let path = location.pathname
    const langRegexp = new RegExp(this.AVAILABLE_LANG)
    if (path.match(langRegexp)) {
      const replaceRegexp = new RegExp(`^/(?:${this.AVAILABLE_LANG})(.*)$`)
      path = path.replace(replaceRegexp, `$1`)
    }
    return path
  }

  validateLocale(locale) {
    const regexp = new RegExp(this.AVAILABLE_LANG)
    return locale.match(regexp) != null
  }

  redirectIfRequired(locale) {
    let url = ''
    let authUrl = ''
    if (location.hostname.match(/\.jp$/) && locale != 'ja') {
      // .jp => .com
      let path = locale == 'en'
        ? this.removeLocalePath()
        : this.appendLocalePath(locale)
      const regexp = new RegExp(`^(.+)${location.hostname}[^\\?]*(\\?.*)?$`)
      url = location.href.replace(regexp, `$1${location.host.replace(/\.jp(:.*)?$/, '.com$1')}${path}$2`)
      authUrl = location.href.replace(regexp, `$1${location.host.replace(/\.jp(:.*)?$/, '.com$1')}/openid/vacation_stay/auth?redirect_path=${path}$2`)
    } else if (location.hostname.match(/\.com$/) && locale == 'ja') {
      // .com => .jp
      const regexp = new RegExp(`^(.+)${location.hostname}[^\\?]*(\\?.*)?$`)
      const path = this.removeLocalePath()
      url = location.href.replace(regexp, `$1${location.host.replace(/\.com(:.*)?$/, '.jp$1')}${path}$2`)
      authUrl = location.href.replace(regexp, `$1${location.host.replace(/\.com(:.*)?$/, '.jp$1')}/openid/vacation_stay/auth?redirect_path=${path}$2`)
    }

    if (url) {
      const getApi = new GetApi()
      getApi.execute(routeUrlHelper.isLoginUsersPath(), { locale: this.pageLocale }).then((res) => {
        if (res.body == true) {
          location.assign(authUrl)
        }else{
          location.assign(url)
        }
      })
    }
  }

  get pageLocale () {
    return this._pageLocale ? this._pageLocale : (this._pageLocale = this._getPageLocale())
  }

  _getPageLocale () {
    const meta = document.querySelector('meta[name="language-locale"]')
    if(meta) {
      return meta.content
    }
    return 'ja'
  }

  get isJapanese() {
    return this.pageLocale == 'ja'
  }

  get isChinese() {
    return this.pageLocale == 'zh-TW'
  }
}

const languageHelper = new LanguageHelper()
export default languageHelper
